import * as typedefs from "../../typedefs";
import { v4 as uuidv4 } from "uuid";

/*eslint no-unused-vars: 0*/

class AnalyticService {

    constructor() {
        this.cookieName = "2917c60e-109d-419c-a026-fb2647ebc207";
        this.userCookieName = "ad305c1d-822e-4d92-b3c5-be519eb96851";

        this.userId = null;
    }

    /**
     * @param {Vue} Vue
     * @returns {String}
     */
    getUserId(Vue) {

        if (!this.userId) {
            const cookieUserId = Vue.$cookies.get(this.userCookieName);
            if (!cookieUserId) {
                this.userId = uuidv4();
                Vue.$cookies.set(this.userCookieName, this.userId);
            } else {
                this.userId = cookieUserId;
            }
        }

        return this.userId;
    }

    /**
     * @param {Vue} Vue
     * @returns {Boolean}
     */
    isEnabled(Vue) {
        return true || Vue.$cookies.get(this.cookieName) === "true";
    }

    /**
     * @param {Vue} Vue     
     */
    enable(Vue) {
        Vue.$cookies.set(this.cookieName, "true");
    }

    /**     
     * @param {Vue} Vue 
     */
    disable(Vue) {
        Vue.$cookies.remove(this.cookieName);
    }

    /**
     * @param {Vue} Vue
     * @param {import("vue-gtag").VueGtag} gtag
     * @param {String} path
     * @returns {Promise}
     */
    pageView(Vue, gtag, path) {
        console.log("GA event "+path);
        return new Promise((resolve, reject) => {
            try {
                if (this.isEnabled(Vue)) {
                    gtag.pageview({
                        page_path: path
                    });
                }

                setTimeout(() => {
                    resolve();
                }, 200);
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * @param {Vue} Vue
     * @param {import("vue-gtag").VueGtag} gtag
     * @param {String} id
     * @param {Number} value
     * @returns {Promise}
     */
    purchase(Vue, gtag, id, value) {
        return new Promise((resolve, reject) => {
            try {
                if (this.isEnabled(Vue)) {
                    gtag.purchase({
                        transaction_id: id,
                        value: value
                    });
                }

                setTimeout(() => {
                    resolve();
                }, 500);
            }
            catch (err) {
                resolve();
            }
        });
    }
}

const analyticService = new AnalyticService();
export { analyticService };