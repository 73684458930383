<template>
  <v-container id="contact" fluid tag="section">
    <v-card rounded class="resultcard" v-show="success">
      <p class="text-center text-h3 pt-5 text-uppercase" :style="{ color: primaryColor }">
        {{ $t("requestsuccess") }}
      </p>
      <v-divider class="ml-5 mr-5"></v-divider>
      <v-card-text>
        <p class="text-h5 text-center">
          <b>
            {{ $t("requestprocessing") }}
          </b>
        </p>
        <p class="text-center">
          <v-icon class="text-center" size="90" color="primary"
            >mdi-email-check</v-icon
          >
        </p>
        <p class="text-h5 text-center">
          <b>
            {{ successText }}
          </b>
        </p>
        <p class="text-center mt-5">
          <v-btn
            color="primary"
            :href="settings.URLToCorporateWebSite"
            target="_blank"
          >            
            <strong>{{ $t("gotowebsite") }}</strong>
          </v-btn>
        </p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/*eslint no-debugger: 0*/
/*eslint no-self-assign: 0*/
/*eslint-disable no-undef*/

import { mapFields } from "vuex-map-fields";
import { v4 as uuidv4 } from "uuid";
import { mandateService } from "../../services/mandateservice";
import { analyticService } from "../../services/analyticsservice";

export default {
  computed: {
    successText() {
      const name = this.$route.name;
      if (name === "successcontact") {
        return config.SUCCESS_SHORTHANDFLOW_CONTACT_TEXT[this.$i18n.locale];
      } else if (name === "successcontactmax") {
        return config.SUCCESS_SHORTHANDFLOW_CONTACT_MAXIMUMCARDS_TEXT[
          this.$i18n.locale
        ];
      }

      return config.SUCCESS_NORMALFLOW_TEXT[this.$i18n.locale];
    },
    primaryColor() {
      return config.PRIMARY_COLOR;
    },
    ...mapFields([
      "companyTitle",
      "companyType",
      "kvkNumber",
      "establishmentNumber",
      "customerFirstname",
      "customerPrefix",
      "customerLastname",
      "country",
      "postcode",
      "housenr",
      "city",
      "street",
      "tel1",
      "tel2",
      "website",
      "emailaddress",
      "contactFirstname",
      "contactPrefix",
      "contactLastname",
      "contactEmailAddress",
      "contactMobilePhone",
      "contactBirthDate",
      "requestid",
      "defaultcard",
      "cards",
      "invoiceEmail",
      "invoiceByEmail",
      "settings",
      "awaitingMandate",
      "shorthandRequest",
      "mandateError",
    ]),
  },
  data() {
    return {
      success: false,
    };
  },
  watch: {},
  methods: {
    checkEmandate() {
      if (this.$route.query.ec) {
        mandateService.checkEmandate(this.$route.query.ec).then((r) => {
          console.log("emandate check done");

          if (r.data && r.data.success) {
            this.success = true;
            this.purge();
          } else {
            this.awaitingMandate = false;
            this.mandateError = true;
            this.$router.replace("/invoice");
          }
        });
      } else {
        console.log(
          "emandate check cannot be done, EC missing from querystring"
        );

        this.success = true;
        this.purge();
      }
    },
    purge() {
      this.$store.dispatch("purge");
      setTimeout(() => {
        this.$store.dispatch("purge");
      }, 200);
  },
  },
  mounted() {
    // If there is no request id then the page was refreshed and the data is lost
    // Try to see if there is data in local storage

    if (!this.requestid) {
      this.$store.dispatch("rebuild");

      if (!this.requestid) {
        this.$router.replace("/");
      } else {
        this.checkEmandate();
      }
    } else {
      this.checkEmandate();
    }
    analyticService.pageView(this, this.$gtag, "/step4_final");
  },
};
</script>

<style scoped>
.main {
  color: #f47920;
}

.resultcard {
  min-height: 325px;
}
</style>  

