<template>
    <v-card rounded class="mt-3">
        <v-card-text class="text-center">
            <v-progress-circular
                v-if="loading"
                :size="165"
                color="primary"
                class="mt-5"
                indeterminate
            ></v-progress-circular>
            <div v-if="!success">
                <h2>
                    {{ $t("idinvalidateerror") }}
                </h2>
                <v-btn class="mt-5" color="primary" tabindex="1" @click="retryValidate()">
                    {{ $t("idinvalidateretry") }}
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

import { mapFields } from "vuex-map-fields";

export default {
    name: "IdinCheck",
    data() {
        return {
            success: true,
            loading: true
        }        
    },
    computed: {
        ...mapFields([
            "idinLock",
            "idinOk",
            "companyType",
            "isMobile",
            "requestid",
            "contactFirstname",
            "contactPrefix",
            "contactLastname",
            "contactEmailAddress",
            "contactBirthDate",
            "customerFirstname",
            "customerPrefix",
            "customerLastname",
            "emailaddress",
            "country",
            "postcode",
            "street",
            "housenr",
            "city",
            "tel1",
            "contactMobilePhone",            
            "settings"
        ])
    },
    methods: {
        /**
         * @returns string
         */
        getSessionId() {            
            return this.$route.query.sessionId || this.$route.query.sessionid;
        },
        validateSession() {
            this.loading = true;

            let sessionId = this.getSessionId();
            if(!sessionId || !sessionId.length) {                
                this.success = false;
                this.loading = false;
            } else {
                this.$http({
                    method: 'POST',
                    url: 'idin/validate',
                    data: {
                        sessionId: sessionId
                    }
                })
                .then((result) => {
                    console.log("idin validation response received");
                    
                    let data = result.data;
                    if(!data || !data.Success) {
                        console.log("idin validation response not successful");
                        console.log(result);

                        this.success = false;
                    } else {
                        this.requestid = data.RequestId;
                        
                        this.companyType = "private";
                        this.contactFirstname = data.ContactFirstName;
                        this.customerFirstname = data.ContactFirstName;
                        this.contactLastname = data.ContactLastName;
                        this.customerLastname = data.ContactLastName;
                        this.contactPrefix = data.ContactPrefixName;
                        this.customerPrefix = data.ContactPrefixName;
                        this.contactEmailAddress = data.ContactEmailAddress;
                        this.emailaddress = data.ContactEmailAddress;
                        this.contactBirthDate = data.ContactBirthDate;                        
                        this.country = data.ContactCountry;
                        this.postcode = data.ContactPostcode;
                        this.street = data.ContactStreet;
                        this.housenr = data.ContactHouseNumber;
                        this.city = data.ContactCity;
                        this.tel1 = data.ContactPhoneNumber;
                        this.contactMobilePhone = data.ContactPhoneNumber;
                        this.idinOk = true;

                        this.idinLock = {
                            contactFirstname: this.contactFirstname && this.contactFirstname.length > 0,
                            customerFirstname: this.customerFirstname && this.customerFirstname.length > 0,
                            contactLastname: this.contactLastname && this.contactLastname.length > 0,
                            customerLastname: this.customerLastname && this.customerLastname.length > 0,
                            contactPrefix: this.contactPrefix && this.contactPrefix.length > 0,
                            customerPrefix: this.customerPrefix && this.customerPrefix.length > 0,
                            contactEmailAddress: this.contactEmailAddress && this.contactEmailAddress.length > 0,
                            emailaddress: this.emailaddress && this.emailaddress.length > 0,
                            contactBirthDate: this.contactBirthDate && this.contactBirthDate.length > 0,
                            country: this.country && this.country.length > 0,
                            postcode: this.postcode && this.postcode.length > 0,
                            street: this.street && this.street.length > 0,
                            housenr: this.housenr && this.housenr.length > 0,
                            city: this.city && this.city.length > 0,
                            tel1: this.tel1 && this.tel1.length > 0,
                            contactMobilePhone: this.contactMobilePhone && this.contactMobilePhone.length > 0
                        };
                        
                        this.$store.dispatch("persist");
                        this.$router.replace("/");
                    }
                })
                .catch((err) => {
                    console.log(err);

                    this.loading = false;
                    this.success = false;
                })
                .then(() => {
                    this.loading = false;
                })                
            }
        },
        retryValidate() {
            this.$router.replace("/");
        }
    },
    mounted() {
        this.validateSession();
    }
}

</script>

<style scoped>
</style>